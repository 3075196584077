
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import * as analytics from '../helpers/analytics'
import { OrganizationModule } from '../store'

@Component({
  name: 'OrganizationAzureDevopsAuthorization'
})
export default class extends Vue {
  organizationModule = getModule(OrganizationModule, this.$store)

  loading = false
  error = ''
  azureDevopsAuthorizationId = ''
  accounts: { id: string, name: string }[] | null = null

  get code () {
    return this.$queryValue('code')
  }

  get state () {
    return decodeURIComponent(this.$queryValue('state') || '')
  }

  get organizationId () {
    return this.state?.split('_')[0]
  }

  get redirectPath () {
    return this.state?.split('_')[1] || '/'
  }

  mounted () {
    analytics.organizationAzureDevopsAuthenticationScreen.track(this, {
      organizationId: [this.organizationId]
    })

    this.error = this.$queryValue('error') || ''
    if (!this.error) {
      this.auth()
    }
  }

  async auth () {
    try {
      if (this.loading) {
        return
      }
      this.loading = true

      if (!this.code) {
        throw new Error('Azure DevOps code was not found')
      }
      if (!this.organizationId) {
        throw new Error('Organization id was not found')
      }

      const azureDevopsAuthorization = await this.organizationModule.azureDevopsAuthorizationCreate({
        code: this.code,
        organizationId: this.organizationId
      })

      this.azureDevopsAuthorizationId = azureDevopsAuthorization.id

      analytics.organizationAzureDevopsAuthorizationCreate.track(this, {
        organizationId: [this.organizationId]
      })

      this.accounts = await this.organizationModule.azureDevopsAuthorizationAvailableAccounts({
        azureDevopsAuthorizationId: this.azureDevopsAuthorizationId,
        organizationId: this.organizationId
      })
    } catch (err: any) {
      this.error = err.body?.message || err.message
      throw err
    } finally {
      this.loading = false
    }
  }

  async updateAccount ({ id, name }: { id: string, name: string }) {
    try {
      if (this.loading) {
        return
      }
      this.loading = true

      await this.organizationModule.azureDevopsAuthorizationUpdate({
        azureDevopsAuthorizationId: this.azureDevopsAuthorizationId,
        organizationId: this.organizationId,
        update: {
          accountId: id
        }
      })

      analytics.organizationAzureDevopsAuthorizationUpdate.track(this, {
        organizationAzureDevopsAccountName: name,
        organizationId: [this.organizationId]
      })

      this.$router.push(this.redirectPath)
    } catch (err: any) {
      this.error = err.body?.message || err.message
      throw err
    }
  }
}
